<template>

  <div class="d-flex justify-content-center animate__animated animate__fadeIn">

    <div class="mt-2" style="width: 930px">
      <div class="logo-container-header">
        <img src="../../assets/logo.jpg" height="80" alt="logo">
      </div>


      <InvoiceFieldGroup v-if="invoice"
                         :readOnly="readOnly"
                         @onReturn="onReturnClick"
                         @onSave="onSaveClick"
                         :invoice="invoice"
      ></InvoiceFieldGroup>

      <div class="form-container-body p-2" v-if="invoice">


        <div class="d-flex justify-content-center">
          <b-pagination
              v-model="currentPage"
              :total-rows="invoice.invoices.length"
              :per-page="1"
              size="sm">

          </b-pagination>
        </div>

        <div class="my-container image-container">
          <img width="100%" :src="resolveImageUrl()" alt="reconocimiento optico de la factura">
        </div>


      </div>


    </div>

  </div>

</template>

<script>

import { mapGetters } from 'vuex';
import ApiService from "@/services/api.service";
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import InvoiceFieldGroup from '@/components/invoiceFieldGroup';
import {logInfo} from "@/helpers";
import {STEPS_ENUM} from "@/store/actions.type";


export default {
  name: 'claim-ocr',
  components: {
    Button,
    InputNumber,
    InvoiceFieldGroup
  },
  data: () => ({
    readOnly: true,
    borrar: null,
    invoice: null,
    currentPage: 1,
    selectedPage: null
  }),
  watch: {
    currentPage(val){
      const idx = val - 1
      this.selectedPage = this.invoice.invoices[idx]
    }

  },

  computed: {
    ...mapGetters(['claim']),
  },
  methods: {
    onReturnClick(e) {
      this.$router.push('/claim')
    },

    async onSaveClick( body ) {
      await ApiService.put(`api/invoice/${this.invoice._id}/user-input`, body)
      logInfo('Campos actualizados')
    },

    resolveImageUrl() {
      const baseUrl = `api/invoice/${this.invoice._id}/img/${this.selectedPage.image}`
      const token = encodeURIComponent(ApiService.getToken())
      return `${ApiService.API_URL}/${baseUrl}?token=${token}`
    },

  },

  async mounted() {

    const _id = this.$route.params.id
    if(!_id){
      return await this.$router.push('/')
    }
    const { data } = await ApiService.get(`api/invoice/${_id}`)
    this.invoice = data
    this.selectedPage = this.invoice.invoices[0]

    this.readOnly = this.claim.activeStep !== STEPS_ENUM.INVOICES
  }
}
</script>
<style scoped>

.intro-container {
  font-family: "reclamatec",serif;

}



</style>


