var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.value)?_c('div',{staticClass:"flex-auto stat-box"},[_c('label',{staticClass:"font-bold block m-0 my-font",attrs:{"for":"locale-user"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"p-inputgroup"},[_c('span',{staticClass:"p-inputgroup-addon p-button-xs",staticStyle:{"min-width":"25px !important"}},[_c('i',{staticClass:"pi",class:{[_vm.icon]: true}})]),(_vm.type==='text')?_c('InputText',{staticClass:"prime-xs",class:{
                 'large-invoice-input': this.size === 'lg',
                 'small-invoice-input': this.size === 'sm',
               },attrs:{"disabled":_vm.readOnly},on:{"input":_vm.handleInput},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):_vm._e(),(_vm.type==='currency')?_c('InputNumber',{class:{
                   'large-invoice-input': this.size === 'lg',
                   'small-invoice-input': this.size === 'sm',
                  },attrs:{"disabled":_vm.readOnly,"inputClass":"prime-xs","minFractionDigits":2},on:{"input":_vm.handleInput},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):_vm._e(),(_vm.type==='date')?_c('Calendar',{class:{
                   'large-invoice-input': this.size === 'lg',
                   'small-invoice-input': this.size === 'sm',
                  },attrs:{"disabled":_vm.readOnly,"locale":"es","dateFormat":"yy-mm-dd","inputClass":"prime-xs"},on:{"input":_vm.handleInput},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):_vm._e(),_c('Button',{staticClass:"p-button-white p-button-xs",attrs:{"disabled":_vm.readOnly,"id":`btn-reset-${_vm.fieldName}`,"icon":"pi pi-replay"},on:{"click":_vm.onReset}}),_c('Button',{staticClass:"p-button-xs",class:{
              'p-button-success':_vm.isVerified,
              'p-button-danger':!_vm.isVerified,
        },attrs:{"disabled":_vm.readOnly,"id":`btn-valid-${_vm.fieldName}`,"icon":"pi pi-verified"},on:{"click":_vm.verifyClick}}),_c('b-tooltip',{attrs:{"placement":"topright","target":`btn-reset-${_vm.fieldName}`,"triggers":"hover"}},[_vm._v(" Restablece valor por defecto ")]),_c('b-tooltip',{attrs:{"placement":"topright","target":`btn-valid-${_vm.fieldName}`,"triggers":"hover"}},[_vm._v(" Valida el campo ")])],1),_c('div',{staticClass:"d-flex flex-column"},[(_vm.model == null)?_c('span',{staticClass:"incomplete-message"},[_vm._v("El campo "+_vm._s(_vm.fieldName)+" es obligatorio")]):_vm._e(),(!_vm.isVerified)?_c('span',{staticClass:"incomplete-message"},[_vm._v("Verifica este campo")]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }