<template>

  <div class="flex-auto stat-box" v-if="value">
    <label for="locale-user" class="font-bold block m-0 my-font"> {{title}} </label>
    <div class="p-inputgroup">

        <span class="p-inputgroup-addon p-button-xs" style="min-width: 25px !important">
            <i class="pi" :class="{[icon]: true} "></i>
        </span>

      <InputText v-if="type==='text'"
                 :disabled="readOnly"
                 v-model="model"
                 class="prime-xs"
                 :class="{
                   'large-invoice-input': this.size === 'lg',
                   'small-invoice-input': this.size === 'sm',
                 }"
                 @input="handleInput"
      />

      <InputNumber v-if="type==='currency'"
                   v-model="model"
                   :disabled="readOnly"
                   inputClass="prime-xs"
                   :minFractionDigits="2"
                   :class="{
                     'large-invoice-input': this.size === 'lg',
                     'small-invoice-input': this.size === 'sm',
                    }"
                   @input="handleInput"
      />

      <Calendar  v-if="type==='date'"
                 v-model="model"
                 :disabled="readOnly"
                 locale="es"
                 dateFormat="yy-mm-dd"
                 inputClass="prime-xs"
                 :class="{
                     'large-invoice-input': this.size === 'lg',
                     'small-invoice-input': this.size === 'sm',
                    }"
                 @input="handleInput"
      />

      <Button :disabled="readOnly"
              @click="onReset"
              :id="`btn-reset-${fieldName}`"
              icon="pi pi-replay"
              class="p-button-white p-button-xs"
      />

      <Button
          :disabled="readOnly"
          :id="`btn-valid-${fieldName}`"
          icon="pi pi-verified"
          @click="verifyClick"
          class="p-button-xs"
          :class="{
                'p-button-success':isVerified,
                'p-button-danger':!isVerified,
          }"
      />

      <b-tooltip placement="topright" :target="`btn-reset-${fieldName}`" triggers="hover">
        Restablece valor por defecto
      </b-tooltip>

      <b-tooltip placement="topright" :target="`btn-valid-${fieldName}`" triggers="hover">
        Valida el campo
      </b-tooltip>

    </div>
    <div class="d-flex flex-column ">
      <span v-if="model == null" class="incomplete-message">El campo {{fieldName}} es obligatorio</span>
      <span v-if="!isVerified" class="incomplete-message">Verifica este campo</span>

    </div>

  </div>

</template>

<script>

import Button from 'primevue/button';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';

import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'invoiceField',

  props: ['value', 'size', 'default', 'type', 'title', 'icon', 'fieldName','readOnly'],

  components: {
    Button,
    InputNumber,
    InputText,
    Calendar
  },

  data() {
    return {
      isVerified: false,
      model: null
    };
  },

  computed: {
  },

  methods: {

    formatValue() {
      const ret = _.clone(this.value)
      ret.value = this.model
      if(this.type === 'date') {
        ret.value = ret.value ? moment(ret.value).format('YYYY-MM-DD') : null
      }
      ret.verified = this.isVerified
      return ret
    },

    verifyClick() {
      this.isVerified = !this.isVerified
      this.handleInput()
    },

    handleInput() {
      this.$emit('input', this.formatValue())
      this.$emit('changed')

    },

    onReset() {
      this.model = this.value.default
      this.handleInput()
    }
  },

  async mounted() {
    this.model = this.value.value || this.value.default
    this.isVerified = this.value.verified
  },

  beforeDestroy() {}
};
</script>

<style>
.incomplete-message {
  font-size: 11px;
  color: indianred;
  font-style: italic;
}

.large-invoice-input {
  width: 158px !important;
}

.small-invoice-input {
  width: 80px !important;
}

.stat-box{
  margin-right: 5px;
  font-family:  sans-serif;
  font-weight: 700!important;
  /*border: 1px solid green;*/
}



</style>