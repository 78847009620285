<template>

  <div class="ocr-input-container p-2 mb-3">
    <div class="pt-2">
      <h5 class="my-title" tabindex="0">Completa los campos</h5>
      <p class="claim-text ">
        Revisa los resultados de la extracción automática de campos de la factura que has importado.<br>
        Pulsa el boton de validación <i class="pi pi-verified"></i> en cada campo para completar el proceso.
      </p>

    </div>

    <div class="d-flex flex-wrap p-fluid" v-if="userInput">



      <InvoiceField
          :readOnly="readOnly"
          v-model="userInput.vendor_name"
          class="mb-2 mr-2"
          @changed="onChanged"
          size="lg"
          type="text"
          icon="pi-user"
          title="Emisor Factura"
          field-name="emisor">
      </InvoiceField>

      <InvoiceField
          :readOnly="readOnly"
          v-model="userInput.vendor_taxid"
          class="mb-2 mr-2"
          @changed="onChanged"
          size="sm"
          type="text"
          icon="pi-credit-card"
          title="CIF/NIF Emisor Factura"
          field-name="cif/nif">
      </InvoiceField>


      <InvoiceField
          :readOnly="readOnly"
          v-model="userInput.customer_name"
          @changed="onChanged"
          class="mb-2 mr-2"
          size="lg"
          type="text"
          icon="pi-user"
          title="Receptor Factura"
          field-name="receptor">
      </InvoiceField>

      <InvoiceField
          :readOnly="readOnly"
          v-model="userInput.customer_taxid"
          @changed="onChanged"
          class="mb-2 mr-2"
          size="sm"
          type="text"
          icon="pi-credit-card"
          title="CIF/NIF Receptor Factura"
          field-name="cif/nif">
      </InvoiceField>

      <InvoiceField
          :readOnly="readOnly"
          v-model="userInput.date"
          @changed="onChanged"
          class="mr-2"
          size="lg"
          type="date"
          icon="pi-calendar-plus"
          title="Fecha Factura"
          field-name="fecha">
      </InvoiceField>

      <InvoiceField
          :readOnly="readOnly"
          v-model="userInput.total"
          @changed="onChanged"
          class="mr-2"
          size="sm"
          type="currency"
          icon="pi-euro"
          title="Total Factura"
          field-name="total">
      </InvoiceField>

    </div>


    <div class="d-flex justify-content-end mt-3 mr-1">
      <div class="d-flex">
        <button id="btn-invoice-field-return"  @click="onReturnClick"
                 class="vf-btn vf-btn-sm ocr-return-btn mr-1">
          <b-icon icon="skip-backward" class="mr-1" style="padding-top: 2px"></b-icon>volver
        </button>

        <ConfirmPopup></ConfirmPopup>

        <button v-if="!readOnly" id="btn-invoice-field-save" @click="onSaveClick"
                 class="vf-btn vf-btn-primary vf-btn-sm ocr-return-btn">
          <b-icon icon="save" class="mr-1" style="padding-top: 2px"></b-icon>guardar
        </button>

        <b-tooltip placement="topright" v-if="!readOnly && !xAllVerified" target="btn-invoice-field-save" triggers="hover">
          Faltan campos por verificar.
        </b-tooltip>

        <b-tooltip placement="topright" v-if="hasChanged" target="btn-invoice-field-return" triggers="hover">
          Hay cambios sin guardar. si vuelves no se guardaran
        </b-tooltip>


      </div>

    </div>

    <br>
    <b-icon icon="info-circle"></b-icon>
    Solo será necesario realizar esta tarea de validación con la primera factura, ya que en el futuro el sistema tendrá en cuenta los campos que has validado

  </div>


</template>

<script>

import Button from 'primevue/button';
import InputNumber from 'primevue/inputnumber';
import InvoiceField from '@/components/invoiceField';
import ConfirmPopup from "primevue/confirmpopup";
import _ from 'lodash'

export default {
  name: 'invoiceFieldGroup',

  props: ['invoice','readOnly'],

  components: {
    InvoiceField,
    ConfirmPopup,
    Button,
    InputNumber
  },

  watch: {

  },

  data() {
    return {
      userInput: null,
      hasChanged: false
    };
  },

  computed: {
    xAllVerified() {
      let ret = true;
      for (const prop of ['customer_name','customer_taxid','date', 'total', 'vendor_name', 'vendor_taxid']){
        ret &&= _.get(this.userInput,`${prop}.verified`)
      }
      return ret
    }
  },

  methods: {

    onChanged() {
      this.hasChanged = true
    },


    async confirm(message, target, cb) {
      this.$confirm.require({
        target ,
        message,
        acceptLabel: 'Aceptar',
        rejectLabel: 'Cancelar',
        icon: 'pi pi-exclamation-triangle',
        accept: cb,
      });

    },

    onReturnClick(event) {
      const self = this
      if(this.hasChanged) {
        this.confirm('Seguro que quieres volver y descartar los cambios?', event.currentTarget, () => {
          self.$emit('onReturn')
        })
      }else{
        this.$emit('onReturn')
      }


    },

    onSaveClick(event) {
      if(!this.xAllVerified) {
        this.confirm('Faltan campos por verificar. Seguro que quieres guardar los cambios?', event.currentTarget, () => {
          this.$emit("onSave",this.userInput)
          this.hasChanged = false
        })
      }else{
        this.$emit("onSave",this.userInput)
        this.hasChanged = false
      }
    }
  },

  async mounted() {
    this.userInput = this.invoice.user_input
  },

  beforeDestroy() {}
};
</script>

<style>

.ocr-return-btn {
  height: 30px
}

.ocr-input-container {
  border-right: 1px solid #dee2e6;;
  border-left: 1px solid #dee2e6;;
  border-bottom: 1px solid #dee2e6;;

}



</style>